@import '~/styles/variables';

.bgShadow {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(#282c32, 0.8);
  z-index: 5;
}

.container {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: $lightTheme;
  z-index: 6;
  overflow: hidden;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.12);
  @include transition(width);
}

.content {
  width: 75vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  @include transition(opacity);
  &,
  & * {
    touch-action: pan-y;
  }
}

.header {
  height: 52px;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 21px;
  align-items: center;
  border-bottom: 1px solid rgba(214, 214, 214, 0.5);
  flex-shrink: 0;
  a {
    line-height: 100%;
    margin-top: 2px;
  }
  .closeIcon {
    svg {
      width: 24px !important;
      height: 24px !important;

      path {
        stroke: $lightThemeText;
      }
    }
  }
}

.body {
  overflow: auto;
}

.footer {
  margin-top: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.dark {
  background-color: $darkThemeLight;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.8);

  .header {
    background-color: $darkTheme;
    border-bottom: 1px solid $secondaryGray;

    .closeIcon svg path {
      stroke: $darkThemeText;
    }
  }
}

.externalContent {
  border-bottom: 1px solid $secondaryGray;
}

.createProjectButton {
  height: 80px;
  color: $lightTheme;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  @include gradientBgTransition(#3d4ff7, #c145ed);
}
