@import '~/styles/variables';

.customMenuItem {
  width: 32px;
  height: 32px;
  padding: 0 !important;

  display: flex;
  justify-content: center;
  align-items: center;

  background: $dark25;
  border-radius: 4px;

  svg {
    width: 26px;
  }
}

.promptBlocker {
  position: fixed;
  bottom: 60px;
  top: 105px;
  left: 0;
  right: 0;
  background: $dark10;
  z-index: 1000;
  &:after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: rgba($dark10, 0.8);
  }
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: rgba($dark10, 0.8);
  }

  &Text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 170px;
  }
  &Arrow {
    position: absolute;
    left: 50%;
    top: 16px;
  }
}

.disabled {
  opacity: 0.3 !important;
}

.exportButton {
  padding: 7px 24px 7px 22px;
  border-radius: 4px;
  margin: 8px;
  &.small {
    padding: 7px;
  }
  .exportButtonLoader {
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    font-size: 14px;
    @include bigDesktop {
      font-size: 16px;
    }
  }
  @include gradientBgTransitionWithBorder(#3d4ff6, #c145ed, $darkThemeLight, 32%, 1px);
  @include bigDesktop {
    padding: 12px 32px 12px 28px;
    &.small {
      padding: 12px;
    }
  }
  @include tablet {
    height: 48px;
    padding: 10px;
    margin: 4px;
    &.small {
      padding: 10px;
    }
  }
  @include mobile {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0 !important;
    margin: 0 16px 0 0;
    background: linear-gradient(100deg, #3d4ff6, #c145ed);
    border: none;
    svg {
      transform: scale(0.8);
    }
    &.small {
      padding: 0 !important;
    }
  }
  &[data-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.dropdownMenu {
  & > div:last-child {
    width: 414px;
    left: auto;
    right: -94px;
    top: calc(100% + 16px);
    border-radius: 8px;
    @include bigDesktop {
      top: calc(100% + 21px);
    }
  }
}

.dropdownMenuShare {
  & > div:last-child {
    width: 500px;
    left: auto;
    right: -36px;
    top: calc(100% + 16px);
    border-radius: 8px;
    @include bigDesktop {
      top: calc(100% + 21px);
    }
  }
}

.dropdownOption {
  height: auto;
  padding: 0 16px 0;

  &:after {
    content: none;
  }
  &:hover {
    background: none !important;
  }
}

.projectName {
  max-width: 128px;
  color: $darkThemeText;
  background: $secondaryGray;
  padding: 6px 18px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include nonMobile {
    margin-left: 16px;
    margin-right: 16px;
  }
  &[data-unsaved='true'] {
    background: none;
    color: $darkThemeDark2Text;
  }
}

.undoButton,
.redoButton {
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $secondaryGray26;
  flex-shrink: 0;
  &[data-disabled='true'] {
    cursor: auto;
    svg path {
      fill: $darkThemeDark2Text;
    }
  }
  svg {
    width: 20px;
  }
}

.redoButton {
  transform: scale(-1, 1);
  border-radius: 100px 0 0 100px;
  @include mobile {
    margin-right: 8px;
  }
}

.undoButton {
  border-radius: 100px 0 0 100px;
  margin-right: 2px;
  margin-left: 8px;
}

.headerMobileNavigation {
  position: relative;

  padding: 4px;

  border-radius: 100px;
  border: 1px solid $secondaryGray15;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .option {
    width: 44px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 2;

    svg {
      width: 20px;
      height: 20px;
      color: #8b8c8e;
      @include transition(all);
    }

    &.active {
      &.chords,
      &.lyrics {
        svg {
          color: $darkThemeText;
        }
      }

      &.settings {
        svg {
          color: $secondaryGray;
        }
      }
    }
  }

  .marker {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);

    width: 44px;
    height: 30px;

    border-radius: 100px;

    z-index: 1;

    @include transition(all);

    &.chords {
      left: 4px;

      background: $secondaryBlue16;
    }

    &.lyrics {
      left: 52px;

      background: $secondaryYellow;
    }

    &.settings {
      left: 100px;

      background: $darkThemeText;
    }
  }
}

.generationField {
  position: relative;
  flex: 1;
  min-height: 50px;
  max-width: 600px;
  margin: 0 48px;

  @include tablet {
    margin: 0 24px;
  }

  @include mobile {
    margin: 0;
    border: 1px solid $secondaryBlue13;
    border-left: none;
    border-right: none;
    height: 52px;
  }

  .generationFieldInner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    padding: 3px;

    display: flex;
    flex-direction: row;

    border-radius: 8px;
    @include gradientBgWithBorder($secondaryBlue13, #3d4ff6, $darkTheme);

    @include mobile {
      border-radius: 0;
      background: $darkTheme;
      position: relative;
      padding: 0;
      height: 100%;
      border: none;
    }

    input {
      flex: 1;
      padding: 12px;

      background: transparent;
      border: none;
      outline: none;
      resize: none;

      font-family: Quicksand;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.01em;
      text-align: left;
      color: $darkThemeText;

      &::placeholder {
        color: $editableTextDefaultColor;
      }
    }

    .generate {
      position: relative;
      height: 42px;
      padding: 0 24px;

      display: flex;
      justify-content: center;
      align-items: center;

      @include gradientBgTransition($secondaryBlue13, #3d4ff6);
      border-radius: 6px;
      cursor: pointer;
      @include transition(all);

      font-family: Quicksand;
      font-size: 13px;
      font-weight: 600;
      line-height: 13px;
      letter-spacing: 0.01em;
      text-align: left;
      color: $darkThemeText;

      &.disabled {
        cursor: default;
        opacity: 0.5;
      }

      .beta {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);

        font-size: 10px;
        line-height: 12px;
        color: $darkThemeText;
        background: $gray30;
        border-radius: 4px;
        padding: 2px 4px;
      }

      @include tablet {
        padding: 0;
        min-width: 42px;
        width: 42px;

        svg {
          width: 24px;
        }
      }

      @include mobile {
        padding: 0;
        height: 50px;
        width: 50px;
        border-radius: 0;

        .beta {
          left: 0;
          right: unset;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.undoRedoDescription {
  white-space: pre-wrap;
}
