@import '~/styles/variables';

.profileButton {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba($darkThemeText, 0.14);
  background-color: transparent;
  border-radius: 50%;
  color: $secondaryGray;
  margin-left: 12px;
  @include mobile {
    width: auto;
    border: none;
    border-radius: 0;
    justify-content: flex-start;
    font-weight: 600;
    margin-left: 16px;
    margin-top: 16px;
    font-size: 14px;
    color: $darkThemeText;
  }

  .profileButtonContent {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    @include bgTransition(rgba($darkThemeText, 0.14), rgba($darkThemeText, 0.1));

    svg {
      width: 16px;
    }

    @include mobile {
      width: 24px;
      height: 24px;
      margin-left: -2px;
      margin-right: 10px;
      background: transparent;
      svg {
        width: 12px;
      }
    }
  }

  .profileButtonPlan {
    position: absolute;
    right: 0;
    top: 0;

    width: 16px;
    height: 16px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &.noSubscription {
    .profileButtonPlan {
      right: -7px;
      top: -2px;

      width: 36px;
      height: 15px;

      img {
        width: 36px;
        height: 15px;
      }
    }
  }

  &.light {
    border-color: rgba(#5a7fff, 0.25);

    .profileButtonContent {
      @include gradientBgTransition(#5a7fff, #7bb8ff);
    }

    @include mobile {
      color: $secondaryGray;

      .profileButtonContent {
        background: transparent;
      }

      svg g {
        fill: $secondaryGray;
      }
    }
  }
}

.container {
  .dropdownMenu {
    & > div:last-child {
      left: auto;
      right: -32px;
      width: 230px;
      top: 69px;
      @include bigDesktop {
        top: 79px;
      }
      @include mobile {
        left: 0;
        top: 0;
        width: 100%;
      }
    }
  }
}

.loginButton {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;

  .loginButtonContent {
    height: 40px;
    span {
      font-size: 14px;
    }
    @include bigDesktop {
      height: 50px;
      span {
        font-size: 16px;
      }
    }
  }

  svg {
    width: 18px;
    height: 18px;

    path {
      stroke: none !important;
    }
  }

  &.light {
    .loginButtonContent {
      height: 50px;
      svg {
        path {
          fill: $secondaryGray !important;
        }
      }

      span {
        color: $secondaryGray !important;
      }

      &:hover,
      &.active {
        background: $secondaryGray13 !important;
      }
    }
  }
}
