@import '~/styles/variables';

.container {
  position: relative;
}

.optionContainer {
  position: relative;
}

.option {
  height: 48px;
  min-height: fit-content;
  user-select: none;
  @include bgTransition(transparent, $secondaryGray11);
  border: none;
  @include transition(all);
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  color: $lightThemeText2;
  padding-left: 16px;
  outline: none;
  position: relative;
  @include mobile {
    min-height: 56px;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      &[data-fill-active='true'] {
        fill: $lightThemeText2;
      }

      &:not([data-fill-active='true']) {
        stroke: $lightThemeText2;
      }
    }
    circle {
      fill: $lightThemeText2;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: none;
    @include transition(background-color);
    border-radius: 0px 4px 4px 0px;
  }

  &[data-active='true'] {
    &:after {
      background-color: #5a7fff;
    }

    background-color: $secondaryGray10;
  }

  @include nonMobile {
    &:hover {
      &:after {
        background-color: #5a7fff;
      }

      background-color: $secondaryGray10;
    }
  }

  @include nonMobile {
    &:hover {
      &[data-sub='true']:after {
        background-color: #5a7fff;
      }

      background-color: $secondaryGray10;
    }
  }

  span {
    margin-right: 18px;
    display: flex;
  }

  .optionRightIcon {
    margin-left: auto;
    margin-right: 2px;
  }

  .optionArrow {
    margin-left: auto;
    margin-right: 14px;
    transform: rotate(90deg);
    width: 18px;
    height: 18px;
    @include transition(transform);
    path {
      stroke: $darkThemeText;
    }

    @include mobile {
      transform: rotate(180deg);
    }

    &[data-open='true'] {
      transform: rotate(0deg);
    }
  }
}

.menuContainer,
.submenu {
  position: absolute;
  width: 180px;
  background: $lightTheme;
  border: 1px solid $secondaryGray11;
  border-radius: 4px;
  display: none;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 100;

  &[data-open='true'] {
    display: flex;
    flex-direction: column;
  }
  &[data-top='true'] {
    top: auto !important;
    bottom: 0;
  }
  &[data-position='left'] {
    left: unset;
    right: 100%;
  }
}

.submenu {
  width: 260px;
}

.menuMobileViewContainer {
  position: relative;
  box-shadow: none !important;
  width: 100%;
  border: none !important;
  background: none !important;
}

.subMenuMobileView {
  position: static;
  box-shadow: none !important;
  width: 100%;
  border: none !important;
  background: none !important;
}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(214, 214, 214, 0.5);
  margin: 8px 0;
}

.menuContainer {
  top: 100%;
  left: 0;
  @include transition(all);
  &[data-portal='true'] {
    @include nonMobile {
      position: fixed;
      z-index: 30;
    }
  }
}

.submenu {
  left: 100%;
  top: 0;
}

.menuContainer.dark {
  &,
  .submenu {
    background: $darkTheme;
    border: 1px solid $secondaryGray;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.8);
  }

  .divider {
    background: $secondaryGray;
  }

  .option {
    color: $darkThemeText;
    @include bgTransition(transparent, #2d2e32);

    &[data-active='true'] {
      background-color: #2d2e32;
    }

    @include nonMobile {
      &:hover {
        background-color: #2d2e32;
      }
    }

    svg {
      path {
        &[data-fill-active='true'] {
          fill: $darkThemeText;
        }

        &:not([data-fill-active='true']) {
          stroke: $darkThemeText;
        }
      }
      circle {
        fill: $darkThemeText;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
