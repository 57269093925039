@import '~/styles/variables';

.header {
  background-color: $lightTheme;
  padding: 0;
  border-bottom: 1px solid rgba(214, 214, 214, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  &.scrollable {
    position: static;
    flex-shrink: 0;
  }

  .content {
    display: flex;
    max-width: $maxDesktopWidth;
    padding-left: 40px;
    padding-right: 40px;
    @include nonTablet {
      &[data-landing='true'] {
        padding-left: 120px;
        padding-right: 120px;
      }
    }
    margin-left: auto;
    margin-right: auto;
    height: 90px;
    align-items: center;

    @include tablet {
      padding-left: 24px;
      padding-right: 24px;
    }

    @include mobile {
      width: 100%;
      justify-content: space-between;
      height: 52px;
      padding: 0;
    }

    &.centerDominate {
      .flexLeft {
        flex: none;
        flex-grow: none;
      }

      .flexCenter {
        flex: 1;
        flex-grow: 1;
        overflow: visible;
      }

      .flexRight {
        flex: none;
        flex-grow: none;
      }
    }
  }

  &.showCoupon .content {
    top: 60px;

    @include mobile {
      top: 50px;
    }
  }

  &.zoomed .content {
    height: 72px;
    @include mobile {
      height: 52px;
    }
    @include bigDesktop {
      height: 90px;
    }
  }
  &.darkLanding .content {
    @include mobile {
      height: 72px;
    }
  }

  .logo {
    align-items: center;
    display: flex;
    margin-right: 24px;
    flex-shrink: 0;
    @include mobile {
      margin-right: 0;
      margin-left: 16px;
      position: relative;
      &[data-small='true'] {
        width: 40px;
      }
      &.logoRight {
        margin-right: 16px;
        margin-left: 0;
      }
    }
  }

  .flexLeft {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-grow: 1;
  }

  .flexCenter {
    display: flex;
    flex-shrink: 1;
    overflow: hidden;
    justify-content: center;
  }

  .flexRight {
    flex: 1;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &.dark {
    background-color: $darkTheme;
    border-bottom: 1px solid $secondaryGray;
  }

  &.darkLanding {
    border-bottom: none;
    background: rgba(23, 23, 26, 0.92);
    backdrop-filter: blur(22px);
  }
}

.pricingButton {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  @include transition(all);

  text-decoration: none !important;
  color: $secondaryGray;
  &:not(.landing) {
    &:hover {
      background: $secondaryGray13;
    }
  }

  margin: 0 24px 0 16px;

  &.landing {
    color: $darkThemeDark2Text;

    &:hover {
      color: $darkThemeText;
    }

    &[data-active='true'] {
      color: $darkThemeText;
    }
  }

  @media (max-width: 660px) {
    display: none;
  }
}

.createProjectContainer {
  position: relative;
}

.createProjectButton {
  padding-left: 32px;
  padding-right: 32px;
  margin: 8px;
  height: 50px;
  color: $lightTheme;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  @include gradientBgTransition(#3d4ff7, #c145ed);
  @media (max-width: 374px) {
    padding: 0 12px;
  }

  &[data-preivew='true'] {
    height: 40px;
    @include bigDesktop {
      height: 50px;
    }
  }

  &[data-hidden='true'] {
    visibility: hidden;
  }
}

.dark {
  .content {
    max-width: none;
  }
}

.tryPlatinumButton {
  background-color: rgba(243, 193, 47, 0.25);
  transition: background-color 250ms ease-out;
  padding: 7px 24px 7px 22px;
  margin: 8px;
  border-radius: 4px;
  @include bigDesktop {
    padding: 12px 24px 12px 22px;
  }

  span {
    color: #f39d48 !important;
    font-size: 14px;
    @include bigDesktop {
      font-size: 16px;
    }
  }

  @include nonMobile {
    &:hover {
      background-color: rgba(243, 193, 47, 0.5);
    }
  }

  @include mobile {
    border-radius: 0;
    flex-shrink: 0;
    margin: 0;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(243, 193, 47, 0.25);
  }

  @include tablet {
    height: 48px;
    padding: 10px;
  }

  &.generator {
    @include gradientBgTransitionWithBorder(#fad846, #f29f46, $darkThemeLight, 17%, 1px);

    span {
      color: $secondaryYellow !important;
    }

    @include nonMobile {
      &:hover {
        background-color: $secondaryBrown2;
      }
    }
    @include mobile {
      border: none;
    }
  }
}

.blogMenuItem {
  margin-right: 16px;
  margin-left: 0;

  &.generator {
    svg {
      path {
        stroke: $darkThemeText !important;
      }
    }
  }

  &.landing {
    path {
      stroke: $darkThemeText;
    }

    background: none !important;

    &:not(:hover):not(&.active) {
      span {
        color: $darkThemeDark2Text;
      }

      svg path {
        stroke: $darkThemeDark2Text;
      }
    }

    @include mobile {
      margin-right: 0;
    }
  }

  &:not(.landing) {
    @include mobile {
      height: 56px;
      justify-content: flex-start;
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 0;
      padding-left: 16px !important;
      position: relative;
      svg {
        margin-right: 8px;
        width: 20px;
      }
      &:after {
        content: '';
        left: 0;
        right: 0;
        bottom: -8px;
        position: absolute;
        border-bottom: 1px solid $secondaryGray;
      }
    }
  }

  &:not(.generator):not(.landing) {
    svg {
      path {
        stroke: $secondaryGray !important;
      }
    }

    span {
      color: $secondaryGray !important;
    }

    &:hover,
    &.active {
      background: $secondaryGray13 !important;
    }

    @include mobile {
      &:after {
        border-bottom: 1px solid rgba(214, 214, 214, 0.5);
      }
    }
  }
}

.mobileMenuButton {
  margin-right: 10px;
  padding: 6px 8px;

  path {
    stroke: $darkTheme;
  }

  &.generator {
    margin-right: 0;
    margin-left: 10px;

    path {
      stroke: $darkThemeText;
    }
  }
}

.stickyGeneratorButton {
  position: fixed;
  z-index: 5;

  &[data-hidden='true'] {
    pointer-events: none;
  }
}

.dropdownMenu {
  & > div:last-child {
    width: 244px;
    left: 0;
    top: calc(100% + 20px);
    @include mobile {
      margin-top: 0 !important;
    }
  }

  &.mobileView {
    border-bottom: 1px solid rgba(214, 214, 214, 0.5);

    & > div:last-child {
      width: 100%;
    }
  }
}

.dropdownOption {
  height: auto;
  padding-right: 16px;
  padding-left: 20px;

  & > span {
    align-self: flex-start;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding-top: 8px;
    @include mobile {
      font-size: 16px;
      line-height: 20px;
    }
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    color: $darkThemeDark2Text;
    margin: 2px 0 0;
    padding-bottom: 8px;
    @include mobile {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @include nonMobile {
    &:hover {
      background-color: #25202d !important;

      &:after {
        background: linear-gradient(103.32deg, #783df7 6.46%, #c145ed 98.38%);
      }

      p {
        color: #a297b4;
      }
    }
  }
}

.dropdownMenu.light {
  .dropdownOption {
    @include nonMobile {
      &:hover {
        background-color: #f8f4ff !important;

        &:after {
          background: linear-gradient(103.32deg, #783df7 6.46%, #c145ed 98.38%);
        }

        p {
          color: $darkThemeDark2Text;
        }
      }
    }
  }
}

.logoContainer {
  position: relative;
  width: 114px;
  height: 15px;

  &.small {
    width: 40px;
    height: 40px;
  }
}
